import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import Loading from "components/loading/Loading";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "config";

const libraries = ["places"];

const DetayHarita = ({ lat, lng, onMarkerPositionChange }) => {
  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  const [map, setMap] = useState(null);
  const merkez = {
    lat: lat,
    lng: lng,
  };

  const mapOptions = {
    language: "tr",
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries, // Sabit değişken olarak geçirme
    language: "tr",
  });

  useEffect(() => {
    if (isLoaded && !map) {
      setMap(map);
    }
  }, [isLoaded, map]);

  const handleMarkerDragEnd = (e) => {
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();
    onMarkerPositionChange({ lat: newLat, lng: newLng });
  };

  if (loadError) {
    return <div>Harita yüklenirken bir hata oluştu.</div>;
  }

  return (
    <>
      {!isLoaded ? (
        <Loading />
      ) : (
        <Card className="mt-5">
          <Card.Body>
            <h2>
              <b>Yatırım Haritası</b>
            </h2>
            <b> Değiştirmek için ortadaki imleci oynatınız</b>
            <hr className="mt-1 mb-4" />
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={merkez}
              zoom={12}
              onLoad={(map) => setMap(map)}
              options={mapOptions}
            >
              <Marker
                key={`${lat}-${lng}`} // veya başka bir benzersiz değer
                position={{
                  lat: lat,
                  lng: lng,
                }}
                draggable={true}
                onDragEnd={handleMarkerDragEnd}
              />
            </GoogleMap>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default DetayHarita;
