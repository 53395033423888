import React, { useEffect, useState, useRef } from "react";
import { Form, Button, Col, Row, Card, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import Loading from "components/loading/Loading";
import * as Yup from "yup";
import Select from "react-select";
import HtmlHead from "components/html-head/HtmlHead";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import Servis from "../../services/servis";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const KurumFaaliyetEkle = () => {
  const [yillar, setYillar] = useState([]);
  const [seciliYil, setSeciliYil] = useState(null);
  const [seciliAy, setSeciliAy] = useState(null);
  const [loading, setLoading] = useState(true);
  const [altAlanVisible, setAltAlanVisible] = useState(false);
  const [editorState, setEditorState] = useState("");
  const [egitimEditorState, setegitimEditorState] = useState("");
  const [saglikEditorState, setsaglikEditorState] = useState("");
  const [tarimEditorState, settarimEditorState] = useState("");
  const [sosyalYardimEditorState, setsosyalYardimEditorState] = useState("");
  const [kirsalAltYapiEditorState, setkirsalAltYapiEditorState] = useState("");
  const [digerKamuHizmetleriEditorState, setdigerKamuHizmetleriEditorState] = useState("");
  const [projeFaaliyetlerEditorState, setprojeFaaliyetlerEditorState] = useState("");

  const [btnLoading, setBtnLoading] = useState(false);

  const { currentUser } = useSelector((state) => state.auth);

  const editorRef = useRef(null);

  const initialValues = {
    ay: "",
    yil: "",
    asayis: "",
    egitim: "",
    saglik: "",
    tarim: "",
    sosyalYardim: "",
    kirsalAltYapi: "",
    digerKamuHizmetleri: "",
    projeFaaliyetler: "",

    yukleyen: "",
    yuklemetarihi: "",
    yukleyentel: "",
    donem: "",
  };

  // eslint-disable-next-line no-unused-vars
  const donemler = [
    { value: "1. Dönem", label: "1. Dönem" },
    { value: "2. Dönem", label: "2. Dönem" },
  ];

  const [seciliDonem, setSeciliDonem] = useState(null);

  const yilCek = async () => {
    try {
      const response = await Servis.getYap("veriler/yilgetir");
      const yillar = response.data.map((yil) => ({
        value: yil.yil,
        label: yil.yil,
      }));
      setYillar(yillar);
      setLoading(false);
    } catch (error) {
      console.error("Yıl çekme hatası:", error);
    }
  };

  useEffect(() => {
    yilCek();
  }, []);

  const aylar = [
    { value: "Ocak", label: "Ocak" },
    { value: "Şubat", label: "Şubat" },
    { value: "Mart", label: "Mart" },
    { value: "Nisan", label: "Nisan" },
    { value: "Mayıs", label: "Mayıs" },
    { value: "Haziran", label: "Haziran" },
    { value: "Temmuz", label: "Temmuz" },
    { value: "Ağustos", label: "Ağustos" },
    { value: "Eylül", label: "Eylül" },
    { value: "Ekim", label: "Ekim" },
    { value: "Kasım", label: "Kasım" },
    { value: "Aralık", label: "Aralık" },
  ];

  const schema = Yup.object({
    ay: Yup.string().required("Ay seçiniz"),
    yil: Yup.string().required("Yıl seçiniz"),
    kurumfaaliyet: Yup.string().required("Kurum faaliyet giriniz"),
    kurumu: Yup.string().required("Kurum giriniz"),
    projeler: Yup.string().required("Projeler giriniz"),
    ilce: Yup.string().required("İlçe giriniz"),
    diger: Yup.string().required("Diğer giriniz"),
    yukleyen: Yup.string().required("Yükleyen giriniz"),
    yuklemetarihi: Yup.string().required("Yükleme tarihi seçiniz"),
    yukleyentel: Yup.string().required("Yükleyen telefon giriniz"),
    donem: Yup.string().required("Dönem seçiniz"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: async (values) => {
      try {
        const contentState = JSON.parse(editorState);
        values.kurumfaaliyet = JSON.stringify(contentState);
        const response = await Servis.KurumFaaliyetEkle(values);
        if (response.status) {
          toast.success(response.message);
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error(error);
        toast.error("İlçe rapor eklenemedi");
      }
    },
  });

  const veriVarsaGetir = async () => {
    setEditorState("");
    if (!seciliYil || !seciliAy || !seciliDonem) {
      toast("Yıl, ay ve dönem seçiniz", { className: "danger" });
      return;
    }

    try {
      const response = await Servis.postEditor("ilcerapor/ilceraporugetir", {
        ilce: currentUser.ilce,
        yil: seciliYil.value,
        ay: seciliAy.value,
        donem: seciliDonem.value,
      });
      if (response.status !== 200) {
        console.error("Unexpected response status:", response.status);
        toast.error("Beklenmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyin.");
        return;
      }

      const responseData = response.data[0];

      if (responseData) {
        setEditorState(responseData.asayis);
        setegitimEditorState(responseData.egitim);
        setsaglikEditorState(responseData.saglik);
        settarimEditorState(responseData.tarim);
        setsosyalYardimEditorState(responseData.sosyalyardim);
        setkirsalAltYapiEditorState(responseData.altyapi);
        setdigerKamuHizmetleriEditorState(responseData.diger);
        setprojeFaaliyetlerEditorState(responseData.projeler);

        setAltAlanVisible(true);
      } else {
        setEditorState("");
        setegitimEditorState("");
        setsaglikEditorState("");
        settarimEditorState("");
        setsosyalYardimEditorState("");
        setkirsalAltYapiEditorState("");
        setdigerKamuHizmetleriEditorState("");
        setprojeFaaliyetlerEditorState("");

        setAltAlanVisible(true);
      }
      formik.setValues({
        ...formik.values,
        yukleyentel: "0" + currentUser.telno,
        yukleyen: currentUser.adsoyad,
      });
    } catch (error) {
      console.error("İlçe Rapor getirme hatası:", error);
      toast.error("İlçe Rapor getirilemedi. Bir hata oluştu.");
    }
  };

  useEffect(() => {
    // Editor yüksekliğini güncelle
    if (editorRef.current) {
      const editorHeight = editorRef.current.getEditor().root.scrollHeight;
      editorRef.current.getEditor().root.style.height = `${editorHeight}px`;
    }
  }, [editorState]);

  const { handleChange, handleSubmit, values, touched, errors } = formik;

  const veriYukle = async () => {
    if (!seciliYil || !seciliAy || !seciliDonem) {
      toast("Yıl, ay ve dönem seçiniz", { className: "danger" });
    } else if (values.yukleyen === "" || values.yukleyentel === "") {
      toast("Yükleyen ve yükleyen telefon alanlarını doldurunuz", {
        className: "danger",
      });
    } else {
      setBtnLoading(true);
      let gidenVeri = {
        ilce: currentUser.ilce,
        asayis: editorState,
        egitim: egitimEditorState,
        saglik: saglikEditorState,
        tarim: tarimEditorState,
        sosyalyardim: sosyalYardimEditorState,
        altyapi: kirsalAltYapiEditorState,
        diger: digerKamuHizmetleriEditorState,
        projeler: projeFaaliyetlerEditorState,
        yukleyen: values.yukleyen,
        yuklemetarihi: values.yuklemetarihi,
        yukleyentel: values.yukleyentel,
        kurumid: currentUser.kurumid,
        yil: seciliYil.value,
        ay: seciliAy.value,
        donem: seciliDonem.value,
        kurumu: currentUser.kurumadi,
      };
      Servis.postYap("ilcerapor/ilceraporekle", gidenVeri).then((response) => {
        if (response.status) {
          toast(response.data.message, { className: "success" });
        } else {
          toast(response.data.message, { className: "danger" });
        }
        setBtnLoading(false);
      });
    }
  };

  return (
    <>
      {loading && <Loading />}
      <HtmlHead title="İlçe Rapor Ekle" />

      <section className="scroll-section" id="scrollbar">
        <Row>
          <Col>
            <Form onSubmit={handleSubmit}>
              <Card className="mb-4">
                <Card.Body>
                  <h2>{currentUser.ilce} İlçesi Rapor Ekleme Formu</h2>
                  <hr className="mb-4" />

                  <Row>
                    <Col md={3} className="mb-3">
                      <Form.Label>Yıl</Form.Label>
                      <Form.Group className="mb-3">
                        <Select
                          options={yillar}
                          value={seciliYil}
                          onChange={(value) => {
                            setSeciliYil(value);
                            formik.setFieldValue("yil", value.value);
                          }}
                          placeholder="Yıl Seçiniz"
                          classNamePrefix="react-select"
                        />
                        {errors.yil && touched.yil && <div className="text-danger">{errors.yil}</div>}
                      </Form.Group>
                    </Col>
                    <Col md={4} className="mb-3">
                      <Form.Label>Ay</Form.Label>
                      <Form.Group className="mb-3">
                        <Select
                          options={aylar}
                          value={seciliAy}
                          onChange={(value) => {
                            setSeciliAy(value);
                            formik.setFieldValue("ay", value.value);
                          }}
                          placeholder="Ay Seçiniz"
                          classNamePrefix="react-select"
                        />
                        {errors.ay && touched.ay && <div className="text-danger">{errors.ay}</div>}
                      </Form.Group>
                    </Col>
                    <Col md={4} className="mb-3">
                      <Form.Label>Dönem</Form.Label>
                      <Form.Group className="mb-3">
                        <Select
                          options={donemler}
                          value={seciliDonem}
                          onChange={(value) => {
                            setSeciliDonem(value);
                            formik.setFieldValue("donem", value.value);
                          }}
                          placeholder="Dönem Seçiniz"
                          classNamePrefix="react-select"
                        />
                        {errors.donem && touched.donem && <div className="text-danger">{errors.donem}</div>}
                      </Form.Group>
                    </Col>
                    <Col md={1} className="mb-2 d-flex align-items-center">
                      <Button variant="secondary" onClick={veriVarsaGetir}>
                        Getir
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Form>
            <Row className="mb-4">
              <Col>
                {altAlanVisible && (
                  <>
                    <Card className="mb-4">
                      <Card.Body>
                        <Row>
                          <Col md={12} className="mb-3">
                            <Form.Group className="mb-3">
                              <h5 className="mt-3 mb-3">
                                <b>Asayiş ve Güvenlik</b>
                              </h5>
                              <hr className="mb-3" />
                              <ReactQuill theme="snow" value={editorState} onChange={setEditorState} ref={editorRef} className="sh-70" />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="mb-4">
                      <Card.Body>
                        <Row>
                          <Col md={12} className="mb-3">
                            <Form.Group className="mb-3">
                              <h5 className="mt-3 mb-3">
                                <b>Eğitim Öğretim</b>
                              </h5>
                              <hr className="mb-3" />
                              <ReactQuill theme="snow" value={egitimEditorState} onChange={setegitimEditorState} ref={editorRef} className="sh-70" />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="mb-4">
                      <Card.Body>
                        <Row>
                          <Col md={12} className="mb-3">
                            <Form.Group className="mb-3">
                              <h5 className="mt-3 mb-3">
                                <b>Sağlık</b>
                              </h5>
                              <hr className="mb-3" />
                              <ReactQuill theme="snow" value={saglikEditorState} onChange={setsaglikEditorState} ref={editorRef} className="sh-70" />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="mb-4">
                      <Card.Body>
                        <Row>
                          <Col md={12} className="mb-3">
                            <Form.Group className="mb-3">
                              <h5 className="mt-3 mb-3">
                                <b>TARIMSAL VE HAYVANCILIK FAALİYETLER</b>
                              </h5>
                              <hr className="mb-3" />
                              <ReactQuill theme="snow" value={tarimEditorState} onChange={settarimEditorState} ref={editorRef} className="sh-70" />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="mb-4">
                      <Card.Body>
                        <Row>
                          <Col md={12} className="mb-3">
                            <Form.Group className="mb-3">
                              <h5 className="mt-3 mb-3">
                                <b>SOSYAL YARDIMLAR</b>
                              </h5>
                              <hr className="mb-3" />
                              <ReactQuill theme="snow" value={sosyalYardimEditorState} onChange={setsosyalYardimEditorState} ref={editorRef} className="sh-70" />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="mb-4">
                      <Card.Body>
                        <Row>
                          <Col md={12} className="mb-3">
                            <Form.Group className="mb-3">
                              <h5 className="mt-3 mb-3">
                                <b>KIRSAL ALT YAPI DURUMU / KÖYDES</b>
                              </h5>
                              <hr className="mb-3" />
                              <ReactQuill theme="snow" value={kirsalAltYapiEditorState} onChange={setkirsalAltYapiEditorState} ref={editorRef} className="sh-70" />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="mb-4">
                      <Card.Body>
                        <Row>
                          <Col md={12} className="mb-3">
                            <Form.Group className="mb-3">
                              <h5 className="mt-3 mb-3">
                                <b>DİĞER KAMU HİZMETLERİ VE YATIRIMLAR</b>
                              </h5>
                              <hr className="mb-3" />
                              <ReactQuill theme="snow" value={digerKamuHizmetleriEditorState} onChange={setdigerKamuHizmetleriEditorState} ref={editorRef} className="sh-70" />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="mb-4">
                      <Card.Body>
                        <Row>
                          <Col md={12} className="mb-3">
                            <Form.Group className="mb-3">
                              <h5 className="mt-3 mb-3">
                                <b>PROJE FAALİYETLER</b>
                              </h5>
                              <hr className="mb-3" />
                              <ReactQuill theme="snow" value={projeFaaliyetlerEditorState} onChange={setprojeFaaliyetlerEditorState} ref={editorRef} className="sh-70" />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                    <Card className="mb-4">
                      <Card.Body>
                        <Row>
                          <Col md={4} className="mb-3">
                            <Form.Label>Yükleyen</Form.Label>
                            <Form.Control type="text" name="yukleyen" value={values.yukleyen} onChange={handleChange} isInvalid={errors.yukleyen && touched.yukleyen} />
                            <Form.Control.Feedback type="invalid">{errors.yukleyen}</Form.Control.Feedback>
                          </Col>

                          <Col md={4} className="mb-3">
                            <Form.Label>Yükleyen Telefon</Form.Label>
                            <NumberFormat
                              format="#(###) ###-####"
                              mask="_"
                              className="form-control"
                              allowEmptyFormatting
                              name="yukleyentel"
                              value={formik.values.yukleyentel}
                              onValueChange={(values) => {
                                const { value } = values;
                                formik.setFieldValue("yukleyentel", value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.yukleyentel}</Form.Control.Feedback>
                          </Col>
                          <Col md={12} className="mb-3">
                            <div className="d-flex justify-content-end">
                              <Button size="xl" onClick={veriYukle} variant="primary">
                                {btnLoading ? ( // btnLoading true ise loading indicator göster
                                  <Spinner animation="border" size="sm" role="status" aria-hidden="true">
                                    <span className="visually-hidden">Giriş Yapılıyor...</span>
                                  </Spinner>
                                ) : (
                                  "Kaydet && Güncelle"
                                )}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default KurumFaaliyetEkle;
