import React, { useState, useEffect } from "react";
import {
  Col,
  Pagination,
  Row,
  Card,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import Loading from "components/loading/Loading";
import HtmlHead from "components/html-head/HtmlHead";
import classNames from "classnames";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import CsLineIcons from "cs-line-icons/CsLineIcons";
import "react-toastify/dist/ReactToastify.css";
import Servis from "services/servis";
import { resimyolu } from "../../config";

const ControlsSearch = ({ tableInstance }) => {
  const {
    setGlobalFilter,
    state: { globalFilter },
  } = tableInstance;

  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((val) => {
    setGlobalFilter(val || undefined);
  }, 200);

  return (
    <>
      <input
        className="form-control form-control-sm datatable-search"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="Arama"
      />
      {value && value.length > 0 ? (
        <span
          className="search-delete-icon"
          onClick={() => {
            setValue("");
            onChange("");
          }}
        >
          <CsLineIcons icon="close" />
        </span>
      ) : (
        <span className="search-magnifier-icon pe-none">
          <CsLineIcons icon="search" />
        </span>
      )}
    </>
  );
};

const ControlsPageSize = ({ tableInstance }) => {
  const {
    setPageSize,
    gotoPage,
    state: { pageSize },
  } = tableInstance;
  const options = [10, 25, 50, 100];
  const [selectedPageSize, setSelectedPageSize] = useState(pageSize);

  const onSelectPageSize = (size) => {
    setSelectedPageSize(size);
    setPageSize(size);
    gotoPage(0);
  };

  return (
    <>
      <Dropdown
        size="sm"
        as={ButtonGroup}
        className="d-inline-block"
        align="end"
      >
        <Dropdown.Toggle variant="outline-muted">
          {selectedPageSize} Kayıt
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-sm dropdown-menu-end">
          {options.map((pSize) => (
            <Dropdown.Item
              key={pSize}
              active={pSize === selectedPageSize}
              onClick={() => onSelectPageSize(pSize)}
            >
              {pSize} Kayıt
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
const Table = ({ tableInstance, className }) => {
  const { getTableProps, headerGroups, page, getTableBodyProps, prepareRow } =
    tableInstance;
  return (
    <>
      <table className={className} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, headerIndex) => (
            <tr
              key={`header${headerIndex}`}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column, index) => {
                return (
                  <th
                    key={`th.${index}`}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={classNames(column.headerClassName, {
                      sorting_desc: column.isSortedDesc,
                      sorting_asc: column.isSorted && !column.isSortedDesc,
                      sorting: column.sortable,
                    })}
                  >
                    {column.render("Header")}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                key={`tr.${i}`}
                {...row.getRowProps()}
                className={`${i % 2 === 0 ? "even" : "odd"}`}
              >
                {row.cells.map((cell, cellIndex) => (
                  <td
                    key={`td.${cellIndex}`}
                    {...cell.getCellProps()}
                    className={cell.column.cellClassName}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

const PaginationControls = ({ tableInstance }) => {
  const {
    canPreviousPage,
    previousPage,
    nextPage,
    canNextPage,
    pageCount,
    state: { pageIndex },
    gotoPage,
  } = tableInstance;

  const [visiblePages, setVisiblePages] = useState([0, 1, 2]);

  useEffect(() => {
    if (pageCount <= 10) {
      setVisiblePages([...Array(pageCount).keys()]);
    } else if (pageIndex < 5) {
      setVisiblePages(Array.from({ length: 10 }, (_, i) => i));
    } else if (pageIndex > pageCount - 6) {
      setVisiblePages(Array.from({ length: 10 }, (_, i) => pageCount - 10 + i));
    } else {
      setVisiblePages(Array.from({ length: 10 }, (_, i) => pageIndex - 5 + i));
    }
  }, [pageIndex, pageCount]);

  return (
    <Pagination size="sm" className="justify-content-center mb-0 mt-3">
      <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        <CsLineIcons icon="arrow-double-left" />
      </Pagination.First>
      <Pagination.Prev
        disabled={!canPreviousPage}
        onClick={() => previousPage()}
      >
        <CsLineIcons icon="chevron-left" />
      </Pagination.Prev>
      {visiblePages.map((page) => (
        <Pagination.Item
          key={page}
          active={pageIndex === page}
          onClick={() => gotoPage(page)}
        >
          {page + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage}>
        <CsLineIcons icon="chevron-right" />
      </Pagination.Next>
      <Pagination.Last
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        <CsLineIcons icon="arrow-double-right" />
      </Pagination.Last>
    </Pagination>
  );
};

const KurumListesi = () => {
  const title = "Kurum Listesi";
  const description = "Kurum Listesi";

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const sonuc = await Servis.getYap("kurumlar/kurumgetir");
    const sirali = sonuc.data.sort((a, b) => {
      return b.kurumadi.localeCompare(a.kurumadi);
    });
    setData(sirali);

    setLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columns = React.useMemo(() => {
    return [
      {
        Header: "Logo",
        accessor: "kurumlogo",
        sortable: true,
        headerClassName: "text-muted text-small ",
        cellClassName: "text-alternate text-left",
        Cell: ({ row }) => {
          return (
            <div className="d-flex justify-content-start">
              <img
                src={resimyolu + row.original.kurumlogo}
                alt="logo"
                className="rounded-circle"
                width="40"
                height="40"
              />
            </div>
          );
        },
      },
      {
        Header: "Kurum Adı",
        accessor: "kurumadi",
        sortable: true,
        headerClassName: "text-muted text-small",
        cellClassName: "text-alternate",
      },
      {
        Header: "İlgili Bakanlık",
        accessor: "bakanlik",
        sortable: true,
        headerClassName: "text-muted text-small",
        cellClassName: "text-alternate",
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, sortBy: [{ id: "name", desc: true }] },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <HtmlHead title={title} description={description} />
          {/* Scrollbar Start */}
          <section className="scroll-section" id="scrollbar">
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <h2>Kurum Listesi</h2>
                    <hr className="mt-3 mb-3" />
                    <Row>
                      <Col>
                        <div className="d-inline-block float-md-start me-1 search-input-container w-100 border border-separator bg-foreground search-sm">
                          <ControlsSearch tableInstance={tableInstance} />
                        </div>
                      </Col>
                      <Col
                        sm="12"
                        md="7"
                        lg="9"
                        xxl="10"
                        className="text-end mb-1"
                      >
                        <div className="d-inline-block">
                          <ControlsPageSize tableInstance={tableInstance} />
                        </div>
                      </Col>
                      <hr className="mt-3 mb-3" />
                      <Col xs="12">
                        <Table
                          className="react-table table table-hover  table-centered mb-0"
                          tableInstance={tableInstance}
                        />
                      </Col>
                      <Col xs="12">
                        <PaginationControls tableInstance={tableInstance} />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </section>
          {/* Scrollbar End */}
        </>
      )}
    </>
  );
};

export default KurumListesi;
