/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import HtmlHead from "components/html-head/HtmlHead";
import { Button, Col, Form, Row, Card } from "react-bootstrap";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "components/loading/Loading";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// eslint-disable-next-line no-unused-vars, import/extensions
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import Servis from "../../services/servis";

const KullaniciDetay = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const description = "";
  const history = useHistory();
  const [kurumlar, setKurumlar] = useState([]);
  const [seciliKurum, setSeciliKurum] = useState({});

  const validationSchema = Yup.object().shape({
    adsoyad: Yup.string().required("Adı zorunludur"),
    kurum: Yup.object().required("Kurum zorunludur"),
    tcno: Yup.string()
      .required("TC No zorunludur")
      .min(11, "TC No 11 haneli olmalıdır")
      .max(11, "TC No 11 haneli olmalıdır"),
    yetki: Yup.object().required("Yetki zorunludur"),
    sifre: Yup.string()
      .required("Şifre zorunludur")
      .min(6, "Şifre en az 6 karakter olmalıdır"),
    telno: Yup.string()
      .min(10, "Telefon numarası 10 haneli olmalıdır")
      .max(10, "Telefon numarası 10 haneli olmalıdır"),
  });

  const [kullanici, setKullanici] = useState({
    adsoyad: "",
    kurumid: "",
    tcno: "",
    yetki: "",
    sifre: "",
    telno: "",
  });

  const yetkiler = [
    { value: "Kurum Kullanıcısı", label: "Kurum Kullanıcısı" },
    { value: "Kurum Yöneticisi", label: "Kurum Yöneticisi" },
    { value: "İlçe Yetkilisi", label: "İlçe Yetkilisi" },
    { value: "Kaymakam", label: "Kaymakam" },
    { value: "Sistem Yöneticisi", label: "Sistem Yöneticisi" },
  ];

  const kurumcek = async () => {
    try {
      const tempData = await Servis.getYap("kurumlar/kurumgetir");
      const kurumlar = tempData.data.map((item) => ({
        value: item.kurumid,
        label: item.kurumadi,
      }));
      kurumlar.sort((a, b) => a.label.localeCompare(b.label));
      setKurumlar(kurumlar);
    } catch (error) {
      console.error("Kurum çekme hatası:", error);
    }
  };

  const initialValues = {
    adsoyad: kullanici.adsoyad,
    kurum: seciliKurum,
    tcno: kullanici.tcno,
    yetki: yetkiler.find((kat) => kat.value === kullanici.yetki) || "",
    sifre: kullanici.sifre,
    telno: kullanici.telno,
  };

  const veriGuncelle = async (values) => {
    try {
      const t = await Servis.postYap(`users/userupdate`, {
        id,
        adsoyad: values.adsoyad,
        kurumid: values.kurum.value,
        kurumadi: seciliKurum.label,
        tcno: values.tcno,
        yetki: values.yetki.label,
        sifre: values.sifre,
        telno: values.telno,
      });
      if (t.data.status === true) {
        toast("Kullanıcı güncelleme başarılı", {
          className: "success",
        });
        history.push("/kullanicilar/liste");
      } else {
        toast(t.data.message, {
          className: "danger",
        });
      }
    } catch (error) {
      toast("Bir hata oluştu", {
        className: "danger",
      });
      console.error("Hata:", error);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        await veriGuncelle(values);
      } catch (error) {
        console.error("Hata oluştu:", error);
        toast("Bir hata oluştu", {
          className: "danger",
        });
      }
    },
  });

  useEffect(() => {
    const getKullanici = async () => {
      try {
        const t = await Servis.getYap(`users/getuser/${id}`);
        setKullanici(t.data);
        kurumcek();
        setLoading(false);
      } catch (error) {
        console.error("Kullanıcı çekme hatası:", error);
      }
    };
    getKullanici();
  }, [id]);

  useEffect(
    () => {
      const seciliKurum =
        kurumlar.find((kat) => kat.value === kullanici.kurumid) || "";
      setSeciliKurum(seciliKurum);
      formik.setValues({
        adsoyad: kullanici.adsoyad,
        kurum: seciliKurum,
        tcno: kullanici.tcno,
        yetki: yetkiler.find((kat) => kat.value === kullanici.yetki) || "",
        sifre: kullanici.sifre,
        telno: kullanici.telno,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [kullanici, kurumlar]
  );

  const { handleSubmit, handleChange, values, touched, errors } = formik;

  const kullaniciSil = async () => {
    try {
      Swal.fire({
        title: "Emin misiniz?",
        text: "Bu işlemi geri alamazsınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Evet, Sil!",
        cancelButtonText: "İptal",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const t = await Servis.getYap(`users/userdelete/${id}`);
          if (t.data.status === true) {
            toast("Kullanıcı silme başarılı", {
              className: "success",
            });
            history.push("/kullanicilar/liste");
          } else {
            toast(t.data.message, {
              className: "danger",
            });
          }
        }
      });
    } catch (error) {
      toast(error, {
        className: "danger",
      });
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <HtmlHead title="Kullanıcı Detay" description={description} />
          <form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <Card body className="mb-5">
                  <Card.Body>
                    <Row className="mb-3 g-3">
                      <Col md="6">
                        <h2>Kullanici Detay</h2>
                      </Col>
                      <Col md="6" className="d-flex justify-content-end">
                        <Button
                          variant="outline-danger"
                          className="btn-icon btn-icon-start ms-1"
                          onClick={kullaniciSil}
                        >
                          <span>Kullanıcı Sil</span>
                        </Button>
                      </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3 g-3">
                      <Col md="6">
                        <Form.Group className="form-group position-relative tooltip-end-top">
                          <Form.Label>T.C. Kimlik No</Form.Label>
                          <Form.Control
                            type="text"
                            name="tcno"
                            value={values.tcno}
                            onChange={handleChange}
                          />
                          {touched.tcno && errors.tcno && (
                            <div className="d-block invalid-tooltip">
                              {errors.tcno}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="form-group position-relative tooltip-end-top">
                          <Form.Label>Adı Soyadı</Form.Label>
                          <Form.Control
                            type="text"
                            name="adsoyad"
                            value={values.adsoyad}
                            onChange={handleChange}
                          />
                          {touched.adsoyad && errors.adsoyad && (
                            <div className="d-block invalid-tooltip">
                              {errors.adsoyad}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="form-group position-relative tooltip-end-top">
                          <Form.Label>Kurumu</Form.Label>
                          <Select
                            options={kurumlar}
                            name="kurum"
                            value={seciliKurum}
                            onChange={(selectedOption) => {
                              formik.setFieldValue("kurum", selectedOption);
                              setSeciliKurum(selectedOption);
                            }}
                            classNamePrefix="react-select"
                            placeholder="Lütfen Seçiniz"
                          />
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="form-group position-relative tooltip-end-top">
                          <Form.Label>Yetki</Form.Label>
                          <Select
                            options={yetkiler}
                            name="yetki"
                            value={values.yetki}
                            onChange={(selectedOption) =>
                              formik.setFieldValue("yetki", selectedOption)
                            }
                            classNamePrefix="react-select"
                            placeholder="Lütfen Seçiniz"
                          />
                          {touched.yetki && errors.yetki && (
                            <div className="d-block invalid-tooltip">
                              {errors.yetki}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="form-group position-relative tooltip-end-top">
                          <Form.Label>Şifre</Form.Label>
                          <Form.Control
                            type="password"
                            name="sifre"
                            value={values.sifre}
                            onChange={handleChange}
                          />
                          {touched.sifre && errors.sifre && (
                            <div className="d-block invalid-tooltip">
                              {errors.sifre}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md="6">
                        <Form.Group className="form-group position-relative tooltip-end-top">
                          <Form.Label>Telefon No</Form.Label>
                          <Form.Control
                            type="text"
                            name="telno"
                            value={values.telno}
                            onChange={handleChange}
                          />
                          {touched.telno && errors.telno && (
                            <div className="d-block invalid-tooltip">
                              {errors.telno}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Row>
                    <Col className="d-flex justify-content-end">
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn-icon btn-icon-end"
                      >
                        <span>Güncelle</span>
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </form>
        </>
      )}
    </>
  );
};

export default KullaniciDetay;
