import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Card, Table, Button } from "react-bootstrap";
import HtmlHead from "components/html-head/HtmlHead";
import Select from "react-select";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Servis from "../../services/servis";

const VerileriListele = () => {
  const title = "Verileri Listele";
  const description = "";
  const [kurumListesi, setKurumListesi] = useState([]);
  const [yillar, setYillar] = useState([]);
  const [seciliYil, setSeciliYil] = useState(null);
  const [seciliKurum, setSeciliKurum] = useState(null);
  const [veriler, setVeriler] = useState([]);

  const tableRef = useRef(null);
  const excelTableRef = useRef(null);

  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const sonuc = await Servis.getYap("kurumlar/kurumgetir");
    const kurumlar = sonuc.data.map((kurum) => {
      return {
        value: kurum.kurumid,
        label: kurum.kurumadi,
      };
    });
    const sirali = kurumlar.sort((a, b) => a.label.localeCompare(b.label));
    setKurumListesi(sirali);

    setLoading(false);
  };

  const yilCek = async () => {
    try {
      const response = await Servis.getYap("veriler/yilgetir");
      const yillar = response.data.map((yil) => ({
        value: yil.yil,
        label: yil.yil,
      }));
      setYillar(yillar);
      setLoading(false);
    } catch (error) {
      console.error("Yıl çekme hatası:", error);
    }
  };

  const veriGetir = async () => {
    try {
      const response = await Servis.getYap("veriler/veribaslikgetir/" + seciliKurum.value);
      const baslikData = response.data.map((doc) => ({
        id: doc.baslikId,
        ...doc,
      }));

      const aylarResponse = await Servis.getYap("veri/veriaylar/" + seciliYil.value);
      const aylarData = aylarResponse.data.map((doc) => ({
        id: doc._id,
        ...doc,
      }));

      const groupedData = baslikData.map((baslik) => ({
        ...baslik,
        aylar: aylarData.filter((ay) => ay.baslikId === baslik.baslikId),
      }));

      setVeriler(groupedData);
    } catch (error) {
      console.error("Veri çekme hatası:", error);
    } finally {
      setLoading(false);
    }
    //   setLoading(true);
  };

  useEffect(() => {
    getData();
    yilCek();
  }, []);

  return (
    <>
      <HtmlHead title={title} description={description} />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h2>Kurum Verilerini Listele</h2>
              <hr className="mt-1 mb-3" />
              <Row>
                <Col md={6}>
                  <Select
                    options={kurumListesi}
                    value={seciliKurum}
                    onChange={(e) => {
                      setSeciliKurum(e);
                    }}
                    classNamePrefix="react-select"
                    placeholder="Kurum Seçiniz"
                  />
                </Col>
                <Col md={4}>
                  <Select
                    options={yillar}
                    value={seciliYil}
                    onChange={(e) => {
                      setSeciliYil(e);
                    }}
                    classNamePrefix="react-select"
                    placeholder="Yıl Seçiniz"
                  />
                </Col>
                <Col md={2}>
                  <Button
                    onClick={() => {
                      veriGetir();
                    }}
                    variant="primary"
                  >
                    Verileri Getir
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {loading && <div className="text-center mt-3">Yükleniyor...</div>}
          <Card className="mt-3">
            <Card.Body>
              <Col md="12" className="d-flex justify-content-end">
                {seciliYil && (
                  <DownloadTableExcel filename={`${seciliYil?.label} ${seciliKurum?.label} Verileri`} sheet="Veriler" currentTableRef={excelTableRef.current}>
                    <Button variant="outline-danger" className="mb-1" size="sm">
                      Excele Aktar
                    </Button>
                  </DownloadTableExcel>
                )}
              </Col>
              <hr />
              <Row>
                <Col md="12" className="mb-3">
                  <div style={{ display: "none" }}>
                    <Table ref={excelTableRef} responsive className="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Veri Başlığı</th>
                          {["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"].map((ay) => (
                            <th key={ay}>{ay}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {veriler.map((veri, index) => (
                          <tr key={veri._id}>
                            <td>{index + 1}</td>
                            <td>{veri.veriadi}</td>
                            {["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"].map((ay) => {
                              const ayVerisi = veri.aylar.find((a) => a.ay === ay);
                              return <td key={ay}>{ayVerisi ? ayVerisi.veri : "-"}</td>;
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                  <Table ref={tableRef} responsive className="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Veri Başlığı</th>
                        {["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"].map((ay) => (
                          <th key={ay}>{ay}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {veriler.map((veri, index) => (
                        <tr key={veri._id}>
                          <td>{index + 1}</td>
                          <td>{veri.veriadi}</td>
                          {["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"].map((ay) => {
                            const ayVerisi = veri.aylar.find((a) => a.ay === ay);
                            return <td key={ay}>{ayVerisi ? ayVerisi.veri : "-"}</td>;
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default VerileriListele;
