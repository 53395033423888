import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loading from "components/loading/Loading";

import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "config";

import Servis from "../../services/servis";

const YatirimHaritasi = () => {
  const libraries = ["places"];
  const containerStyle = {
    width: "100%",
    height: "800px",
  };

  const center = {
    lat: 38.38539785621906,
    lng: 34.00846437656472,
  };

  const [gelenYatirimlar, setGelenYatirimlar] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(true);

  const yatirimlariGetir = async () => {
    setLoading(true);
    const resp = await Servis.getYap("yatirimlar/hepsinigetir");
    if (resp.status) {
      setGelenYatirimlar(resp.data);
      setLoading(false);
    }
  };

  const formatter = new Intl.NumberFormat("tr-TR", {
    style: "currency",
    currency: "TRY",
    currencyDisplay: "symbol",
    minimumFractionDigits: 2,
  });

  const mapOptions = {
    language: "tr",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    language: "tr",
    libraries: libraries,
  });

  useEffect(
    () => {
      if (isLoaded && !map) {
        yatirimlariGetir();
      }
    },
    // eslint-disable-next-line
    [isLoaded, map]
  );
  const tamamlananlar = "https://maps.google.com/mapfiles/ms/micons/blue.png";

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Card>
            <Card.Body>
              <h3>Yatırım Haritası</h3>
              <hr className="mt-0 mb-4" />
              {isLoaded ? (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={10}
                  onLoad={(map) => setMap(map)}
                  options={mapOptions}
                >
                  {gelenYatirimlar.map((yatirim, index) => (
                    <Marker
                      key={index}
                      position={{ lat: yatirim.lat, lng: yatirim.lng }}
                      title={yatirim.yatirimadi}
                      onClick={() => setSelectedMarker(yatirim)}
                      icon={{
                        scaledSize: new window.google.maps.Size(40, 40),
                        url:
                          yatirim.durumu === "Yatırım Tamamlandı"
                            ? tamamlananlar
                            : "https://maps.google.com/mapfiles/ms/micons/red.png",
                      }}
                    />
                  ))}

                  {selectedMarker && (
                    <InfoWindow
                      position={{
                        lat: selectedMarker.lat,
                        lng: selectedMarker.lng,
                      }}
                      onCloseClick={() => setSelectedMarker(null)}
                    >
                      <div>
                        <p>{selectedMarker.yatirimadi}</p>
                        <p>{formatter.format(selectedMarker.yatirimtutari)}</p>
                        <Link
                          to={`/yatirimlar/yatirimdetay/${selectedMarker._id}`}
                        >
                          İncele
                        </Link>
                      </div>
                    </InfoWindow>
                  )}
                </GoogleMap>
              ) : (
                <Loading />
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default YatirimHaritasi;
