import React, { useState } from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const EkleResimler = ({ resimEklendi }) => {
  const [resimler, setResimler] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const resimYukle = (e) => {
    const files = e.target.files;
    const fileArray = Array.from(files);
    const fileArrayUrl = fileArray.map((file) => URL.createObjectURL(file));
    setResimler((prevImages) => prevImages.concat(fileArrayUrl));
    resimEklendi(fileArray);
  };

  const openLightbox = (index) => {
    setSelectedImage(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setSelectedImage(null);
    setIsOpen(false);
  };

  const resimSil = (resim) => {
    setResimler((prevImages) => prevImages.filter((image) => image !== resim));
    resimEklendi(resimler.filter((image) => image !== resim));
  };

  return (
    <Card>
      <Card.Body>
        <h4>Resim Ekle</h4>
        <hr className="mt-0 mb-4" />
        <Col mb={12} xl={12} className="mt-5">
          <Row>
            <Col mb={6} xl={6} className="mt-5">
              <Form.Group className="mb-3">
                <Form.Label>Yeni Resim Ekle</Form.Label>
                <Form.Control type="file" multiple onChange={resimYukle} />
              </Form.Group>
            </Col>
          </Row>
          <hr className="mt-0 mb-4" />
          <Row className="row-cols-1 row-cols-xl-5 g-2">
            {resimler.map((resim, index) => (
              <Col key={index}>
                <div className="card hover-img-scale-up position-relative">
                  <img
                    className="card-img sh-15 sh-sm-25 scale cursor-pointer"
                    alt={resim}
                    onClick={() => openLightbox(index)}
                    src={resim}
                  />
                </div>
                <div className="d-flex justify-content-end mt-2">
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => resimSil(resim)}
                  >
                    Sil
                  </Button>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
        {isOpen && (
          <Lightbox
            mainSrc={resimler[selectedImage]}
            nextSrc={resimler[(selectedImage + 1) % resimler.length]}
            prevSrc={
              resimler[(selectedImage + resimler.length - 1) % resimler.length]
            }
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() =>
              setSelectedImage(
                (selectedImage + resimler.length - 1) % resimler.length
              )
            }
            onMoveNextRequest={() =>
              setSelectedImage((selectedImage + 1) % resimler.length)
            }
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default EkleResimler;
