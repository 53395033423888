import React, { useState, useEffect } from "react";
import { Row, Col, Card, Form, Button, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import Loading from "components/loading/Loading";
import NumberFormat from "react-number-format";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Servis from "../../services/servis";
import { ilceler } from "../../config";

const Profile = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const [seciliIlce, setSeciliIlce] = useState();

  const initialValues = {
    adsoyad: currentUser.adsoyad,
    ilce: currentUser.ilce,
    telno: currentUser.telno,
    sifre: currentUser.sifre,
    sifretekrar: currentUser.sifre,
  };

  const validationSchema = Yup.object({
    adsoyad: Yup.string().required("Ad Soyad alanı zorunludur."),
    ilce: Yup.string().required("İlçe alanı zorunludur."),
    telno: Yup.string().required("Telefon Numarası alanı zorunludur."),
    sifre: Yup.string().required("Şifre alanı zorunludur."),
    sifretekrar: Yup.string().oneOf(
      [Yup.ref("sifre"), null],
      "Şifreler uyuşmuyor"
    ),
  });

  const [tcVeKurum, setTcVeKurum] = useState("");

  const onIlceChange = (selectedOption) => {
    setSeciliIlce(selectedOption);
    formik.setFieldValue("ilce", selectedOption.value);
  };

  const onSubmit = async (values) => {
    setBtnLoading(true);
    try {
      const { adsoyad, ilce, telno, sifre } = values;
      await Servis.postYap("users/guncelle", {
        adsoyad,
        ilce,
        telno,
        sifre,
        tcno: currentUser.tcno,
      });
      toast("Bilgileriniz başarıyla güncellendi.", {
        className: "success",
      });
    } catch (error) {
      toast("Bir hata oluştu. Lütfen tekrar deneyiniz." + error, {
        className: "danger",
      });
    } finally {
      setBtnLoading(false);
    }
  };

  useEffect(
    () => {
      setSeciliIlce(ilceler.find((i) => i.value === currentUser.ilce));
      const getData = async () => {
        try {
          const resp = await Servis.getYap(
            "users/kullanicidetay/" + currentUser.tcno
          );
          if (resp.status) {
            formik.setValues({
              adsoyad: resp.data.adsoyad,
              ilce: resp.data.ilce,
              telno: resp.data.telno,
              sifre: resp.data.sifre,
              sifretekrar: resp.data.sifre,
            });
            setTcVeKurum(resp.data.tcno + " / " + resp.data.kurumadi);
            setSeciliIlce(ilceler.find((i) => i.value === resp.data.ilce));
          }
        } catch (error) {
          toast("Bir hata oluştu. Lütfen tekrar deneyiniz." + error, {
            className: "danger",
          });
        }
      };
      getData();
      setLoading(false);
    },
    // eslint-disable-next-line
    []
  );

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { touched, errors } = formik;

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <h3>Bilgilerim Sayfası</h3>
                  <h6 className="text-muted">
                    {" "}
                    Bilgilerinizi bu sayfada güncelleyebilirsiniz.
                  </h6>
                  <hr className="mt-0 mb-4" />
                  <Row className="mb-3">
                    <Col md={6} className="mb-4">
                      <Form.Group>
                        <Form.Label>Adınız Soyadınız</Form.Label>
                        <Form.Control
                          type="text"
                          name="adsoyad"
                          value={formik.values.adsoyad || ""}
                          onChange={formik.handleChange}
                        />
                        {errors.adsoyad && touched.adsoyad && (
                          <div className="d-block invalid-tooltip">
                            {errors.adsoyad}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-4">
                      <Form.Group>
                        <Form.Label>T.C. No / Kurumu</Form.Label>
                        <Form.Control
                          type="text"
                          name="tcVeKurum"
                          value={tcVeKurum || ""}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-4">
                      <Form.Group className="form-group position-relative tooltip-end-top">
                        <Form.Label>İlçe</Form.Label>
                        <Select
                          options={ilceler}
                          value={seciliIlce}
                          onChange={onIlceChange}
                          classNamePrefix="react-select"
                          placeholder="Lütfen Seçiniz"
                        />
                        {errors.ilce && touched.ilce && (
                          <div className="d-block invalid-tooltip">
                            {errors.ilce}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-4">
                      <Form.Group className="form-group position-relative tooltip-end-top">
                        <Form.Label>Telefon Numaranız</Form.Label>
                        <NumberFormat
                          format="(###) ### ## ##"
                          mask="_"
                          className="form-control"
                          name="telefon"
                          value={formik.values.telno || ""}
                          onChange={formik.handleChange}
                        />
                        {errors.telno && touched.telno && (
                          <div className="d-block invalid-tooltip">
                            {errors.telno}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-4">
                      <Form.Group>
                        <Form.Label>Şifre</Form.Label>
                        <Form.Control
                          type="password"
                          name="sifre"
                          value={formik.values.sifre || ""}
                          onChange={formik.handleChange}
                        />
                        {errors.sifre && touched.sifre && (
                          <div className="d-block invalid-tooltip">
                            {errors.sifre}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-4">
                      <Form.Group>
                        <Form.Label>Şifre Tekrar</Form.Label>
                        <Form.Control
                          type="password"
                          name="sifretekrar"
                          value={formik.values.sifretekrar || ""}
                          onChange={formik.handleChange}
                        />
                        {errors.sifretekrar && touched.sifretekrar && (
                          <div className="d-block invalid-tooltip">
                            {errors.sifretekrar}
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col md={12} className="mb-4">
                      <div className="d-flex justify-content-end">
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={!formik.dirty || btnLoading}
                          size="lg"
                        >
                          {btnLoading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            "Güncelle"
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default Profile;
