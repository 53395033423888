import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import HtmlHead from "components/html-head/HtmlHead";
import Select from "react-select";
import Servis from "../../services/servis";

const VerileriListele = () => {
  const title = "Verileri Listele";
  const description = "";
  const [kurumListesi, setKurumListesi] = useState([]);
  const [seciliKurum, setSeciliKurum] = useState(null);
  const [veriler, setVeriler] = useState([]);

  const [loading, setLoading] = useState(true);

  const getData = async () => {
    const sonuc = await Servis.getYap("kurumlar/kurumgetir");
    const kurumlar = sonuc.data.map((kurum) => {
      return {
        value: kurum.kurumid,
        label: kurum.kurumadi,
      };
    });
    const sirali = kurumlar.sort((a, b) => a.label.localeCompare(b.label));
    setKurumListesi(sirali);

    setLoading(false);
  };

  const veriGetir = async () => {
    try {
      const reps = await Servis.postYap("sabitveriler/kurumsabitveriler", {
        kurumid: seciliKurum.value,
      });
      setVeriler(reps.data);
    } catch (error) {
      console.error("Veri çekme hatası:", error);
    } finally {
      setLoading(false);
    }
    //   setLoading(true);
  };

  useEffect(() => {
    getData();
  }, []);

  const kurumOnChamge = async (e) => {
    setSeciliKurum(e);
    veriGetir();
  };

  return (
    <>
      <HtmlHead title={title} description={description} />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h2>Kurum Verilerini Listele</h2>
              <hr className="mt-1 mb-3" />
              <Row>
                <Col md={6}>
                  <Select
                    options={kurumListesi}
                    value={seciliKurum}
                    onChange={kurumOnChamge}
                    classNamePrefix="react-select"
                    placeholder="Kurum Seçiniz"
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="mt-3">
            <Card.Body>
              {loading ? (
                <div className="text-center">Veriler yükleniyor...</div>
              ) : (
                <Table responsive hover size="lg">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Veri Adı</th>
                      <th>Veri</th>
                    </tr>
                  </thead>
                  <tbody>
                    {veriler.map((veri, index) => (
                      <tr key={veri._id}>
                        <td>{index + 1}</td>
                        <td>{veri.veriadi}</td>
                        <td>{veri.veri}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default VerileriListele;
