import { LAYOUT, MENU_BEHAVIOUR, NAV_COLOR, MENU_PLACEMENT, RADIUS, THEME_COLOR, USER_ROLE } from "constants.js";

export const IS_DEMO = false;
export const IS_AUTH_GUARD_ACTIVE = true;
export const SERVICE_URL = "/app";
export const USE_MULTI_LANGUAGE = false;

export const REACT_HELMET_PROPS = {
  defaultTitle: "Vizyon Aksaray Yönetim Paneli",
  titleTemplate: "%s | Vizyon Aksaray Yönetim Paneli",
};

export const DEFAULT_PATHS = {
  APP: "/",
  LOGIN: "/login",
  NOTFOUND: "/page-not-found",
  UNAUTHORIZED: "/unauthorized",
  INVALID_ACCESS: "/invalid-access",
  SIFREMI_UNUTTUM: "/sifremi-unuttum",
};

export const DEFAULT_SETTINGS = {
  MENU_PLACEMENT: MENU_PLACEMENT.Vertical,
  MENU_BEHAVIOUR: MENU_BEHAVIOUR.Pinned,
  LAYOUT: LAYOUT.Boxed,
  RADIUS: RADIUS.Rounded,
  COLOR: THEME_COLOR.LightBlue,
  NAV_COLOR: NAV_COLOR.Default,
  USE_SIDEBAR: false,
};

export const DEFAULT_USER = {
  id: 1,
  name: "Oğuz ÖZGÜL",
  thumb: "/img/profile/profile-9.webp",
  role: USER_ROLE.Admin,
  email: "lisajackson@gmail.com",
};

// export const tarihFormat = (tarih) => {
//   const date = new Date(tarih);
//   const yil = date.getFullYear();
//   const ay = String(date.getMonth() + 1).padStart(2, "0");
//   const gun = String(date.getDate()).padStart(2, "0");
//   return `${gun}.${ay}.${yil}`;
// };

export const tarihFormat = (tarih) => {
  // Gelen tarihi parçalara ayır
  const parcalar = tarih.split(".");

  // Parçaları al
  const ay = parcalar[0];
  const gun = parcalar[1];
  const yil = parcalar[2];

  // Yeni tarih string'ini oluştur ve döndür
  return `${ay}.${gun}.${yil}`;
};

export const saatFormat = (tarih) => {
  const date = new Date(tarih);
  const yil = date.getFullYear();
  const ay = String(date.getMonth() + 1).padStart(2, "0");
  const gun = String(date.getDate()).padStart(2, "0");
  const saat = String(date.getHours()).padStart(2, "0");
  const dakika = String(date.getMinutes()).padStart(2, "0");
  return `${gun}.${ay}.${yil} ${saat}:${dakika}`;
};
export const ilceler = [
  { value: "Merkez", label: "Merkez" },
  { value: "Ağaçören", label: "Ağaçören" },
  { value: "Eskil", label: "Eskil" },
  { value: "Gülağaç", label: "Gülağaç" },
  { value: "Güzelyurt", label: "Güzelyurt" },
  { value: "Ortaköy", label: "Ortaköy" },
  { value: "Sarıyahşi", label: "Sarıyahşi" },
  { value: "Sultanhanı", label: "Sultanhanı" },
];

export const REDUX_PERSIST_KEY = "vizyonaksaray-persist";

export const GOOGLE_MAPS_API_KEY = "AIzaSyByM78Ad6DX8lnHUhm8dDuLyrjRUU2m7EU";

export const API_LINK = "https://yeniapi.vizyonaksaray.net/";

//export const API_LINK = "http://localhost:3001/";

export const resimyolu = "https://d2r78xt1ykaxsq.cloudfront.net/";

export const apiresimyolu = "http://localhost:3001/api/yukle/";
