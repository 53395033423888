import React, { useEffect, useState } from "react";
import { Row, Col, Card, Form, Button, Spinner } from "react-bootstrap";
import HtmlHead from "components/html-head/HtmlHead";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loading from "components/loading/Loading";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Servis from "services/servis";

const UserAdd = () => {
  const history = useHistory();
  const title = "Kullanıcı Ekle";
  const description = "Kullanıcı Ekle";
  const [kurumlar, setKurumlar] = useState([]);
  const [seciliKurum, setSeciliKurum] = useState(null);
  const [seciliYetki, setSeciliYetki] = useState(null);
  const [loading, setLoading] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    tcno: Yup.string()
      .required("TC Kimlik No zorunludur")
      .min(11, "TC Kimlik No 11 karakter olmalıdır")
      .max(11, "TC Kimlik No 11 karakter olmalıdır"),
    telno: Yup.string()
      .required("Telefon Numarası zorunludur")
      .min(10, "Telefon Numarası 10 karakter olmalıdır")
      .max(10, "Telefon Numarası 10 karakter olmalıdır"),
    adsoyad: Yup.string().required("Ad ve soyad zorunludur"),
    yetki: Yup.string().required("Yetki zorunludur"),
    kurum: Yup.string().required("Kurum zorunludur"),
    sifre: Yup.string().required("Şifre zorunludur"),
    sifretekrar: Yup.string().oneOf(
      [Yup.ref("sifre"), null],
      "Şifreler uyuşmuyor"
    ),
  });

  const formik = useFormik({
    initialValues: {
      mail: "",
      adsoyad: "",
      yetki: "",
      kurum: "",
      sifre: "",
      sifretekrar: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        if (seciliYetki === null || seciliKurum === null) {
          toast("Lütfen yetki ve kurum seçiniz", {
            className: "danger",
          });
        } else {
          setBtnLoading(true);
          const data = {
            tcno: values.tcno,
            adsoyad: values.adsoyad,
            yetki: values.yetki,
            kurumadi: seciliKurum.label,
            kurumid: seciliKurum.value,
            sifre: values.sifre,
            telno: values.telno,
          };
          const response = await Servis.postYap("users/useradd", data);
          if (response.status === 200) {
            toast("Kullanıcı eklendi", {
              className: "success",
            });
          } else {
            toast("Kullanıcı eklenemedi", {
              className: "danger",
            });
          }

          formik.resetForm();
          setSeciliKurum(null);
          setSeciliYetki(null);
          setBtnLoading(false);
          history.push("/kullanicilar/liste");
        }
      } catch (error) {
        console.error("Kullanıcı ekleme hatası:", error);
        toast("Kullanıcı ekleme hatası", {
          className: "danger",
        });
        setBtnLoading(false);
      }
    },
  });

  const kurumcek = async () => {
    try {
      const tempData = await Servis.getYap("kurumlar/kurumgetir");
      const kurumlar = tempData.data.map((item) => {
        return { value: item.kurumid, label: item.kurumadi };
      });
      kurumlar.sort((a, b) => a.label.localeCompare(b.label));
      setKurumlar(kurumlar);
      setLoading(false);
    } catch (error) {
      console.error("Kurum çekme hatası:", error);
    }
  };

  useEffect(() => {
    kurumcek();
  }, []);

  const yetkiler = [
    { value: "Kurum Kullanıcısı", label: "Kurum Kullanıcısı" },
    { value: "Kurum Yöneticisi", label: "Kurum Yöneticisi" },
    { value: "İlçe Yetkilisi", label: "İlçe Yetkilisi" },
    { value: "Kaymakam", label: "Kaymakam" },
    { value: "Sistem Yöneticisi", label: "Sistem Yöneticisi" },
  ];

  const { handleChange, handleSubmit, values, touched, errors } = formik;

  return (
    <>
      {loading && <Loading />}
      <HtmlHead title={title} description={description} />
      <section className="scroll-section" id="scrollbar">
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <h2>Kullanıcı Ekleme Formu</h2>
                <hr className="mb-4" />
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col md="6" className="mb-3">
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="tcno">TC Kimlik No</Form.Label>
                        <Form.Control
                          id="tcno"
                          name="tcno"
                          type="text"
                          placeholder="TC Kimlik No"
                          value={values.tcno}
                          onChange={handleChange}
                          isInvalid={touched.tcno && errors.tcno}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.tcno}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6" className="mb-3">
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="adsoyad">Ad Soyad</Form.Label>
                        <Form.Control
                          id="adsoyad"
                          name="adsoyad"
                          type="text"
                          placeholder="Ad Soyad"
                          value={values.adsoyad}
                          onChange={handleChange}
                          isInvalid={touched.adsoyad && errors.adsoyad}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.adsoyad}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6" className="mb-3">
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="yetki">Yetki</Form.Label>
                        <Select
                          options={yetkiler}
                          value={seciliYetki}
                          onChange={(selectedOption) => {
                            formik.setFieldValue(
                              "yetki",
                              selectedOption ? selectedOption.value : null
                            );
                            setSeciliYetki(selectedOption);
                          }}
                          classNamePrefix="react-select"
                          placeholder="Lütfen Seçiniz"
                          isInvalid={touched.yetki && errors.yetki}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.yetki}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6" className="mb-3">
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="kurum">Kurum</Form.Label>
                        <Select
                          options={kurumlar}
                          value={seciliKurum}
                          onChange={(selectedOption) => {
                            formik.setFieldValue(
                              "kurum",
                              selectedOption ? selectedOption.value : null
                            );
                            setSeciliKurum(selectedOption);
                          }}
                          classNamePrefix="react-select"
                          placeholder="Lütfen Seçiniz"
                          isInvalid={touched.kurum && errors.kurum}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.kurum}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6" className="mb-3">
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="sifre">Şifre</Form.Label>
                        <Form.Control
                          id="sifre"
                          name="sifre"
                          type="password"
                          placeholder="Şifre"
                          value={values.sifre}
                          onChange={handleChange}
                          isInvalid={touched.sifre && errors.sifre}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.sifre}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6" className="mb-3">
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="sifretekrar">
                          Şifre Tekrar
                        </Form.Label>
                        <Form.Control
                          id="sifretekrar"
                          name="sifretekrar"
                          type="password"
                          placeholder="Şifre Tekrar"
                          value={values.sifretekrar}
                          onChange={handleChange}
                          isInvalid={touched.sifretekrar && errors.sifretekrar}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.sifretekrar}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6" className="mb-3">
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor="telno">
                          Telefon Numarası
                        </Form.Label>
                        <Form.Control
                          id="telno"
                          name="telno"
                          type="text"
                          placeholder="Telefon Numarası"
                          value={values.telno}
                          onChange={handleChange}
                          isInvalid={touched.telno && errors.telno}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.telno}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md="6" className="mb-3">
                      <div className="d-flex justify-content-end">
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={btnLoading}
                        >
                          {btnLoading && (
                            <Spinner
                              animation="border"
                              size="sm"
                              className="me-2"
                            />
                          )}
                          Kaydet
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default UserAdd;
