import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import Loading from "components/loading/Loading";
import { GOOGLE_MAPS_API_KEY } from "config";

const libraries = ["places"];

const EkleHarita = ({ onMarkerPositionChange, yeniKoordinat }) => {
  const containerStyle = {
    width: "100%",
    height: "500px",
  };

  // eslint-disable-next-line
  const [map, setMap] = useState(null);
  const [loading, setLoading] = useState(true);
  const [koordinat, setKoordinat] = useState(
    yeniKoordinat || {
      lat: 38.371375742062256,
      lng: 34.02692843680906,
    }
  );

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    language: "tr",
    libraries: libraries,
  });

  useEffect(
    () => {
      if (isLoaded) {
        setLoading(false);
      }
      if (
        yeniKoordinat &&
        (yeniKoordinat.lat !== koordinat.lat ||
          yeniKoordinat.lng !== koordinat.lng)
      ) {
        setKoordinat(yeniKoordinat);
      }
    },
    // eslint-disable-next-line
    [yeniKoordinat, isLoaded]
  );

  useEffect(() => {
    if (yeniKoordinat) {
      setKoordinat(yeniKoordinat);
    }
  }, [yeniKoordinat]);

  const handleMarkerDragEnd = (e) => {
    const newLat = e.latLng.lat();
    const newLng = e.latLng.lng();
    const newKoordinat = { lat: newLat, lng: newLng };
    setKoordinat(newKoordinat);
    onMarkerPositionChange(newKoordinat);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Card className="mt-5 mb-5">
          <Card.Body>
            <h2>Yatırım Haritası</h2>
            <hr className="mt-0 mb-4" />
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={koordinat}
              zoom={11}
              onLoad={(map) => setMap(map)}
              options={{ language: "tr" }}
            >
              <Marker
                position={koordinat}
                draggable={true}
                onDragEnd={handleMarkerDragEnd}
              />
            </GoogleMap>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default EkleHarita;
