import React from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Servis from "../services/servis";

const LogoutPage = () => {
  const history = useHistory();

  const handleLogout = async () => {
    try {
      const result = await Swal.fire({
        title: "Çıkış yapmak istediğinizden emin misiniz?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Evet, Çıkış Yap",
        cancelButtonText: "İptal",
        reverseButtons: true,
      });

      if (result.isConfirmed) {
        console.log("Çıkış yapılıyor...");
        Servis.logout(); // Sunucudan çıkış yap
        localStorage.removeItem("token"); // Token'ı localStorage'dan sil
        localStorage.removeItem("user"); // Kullanıcı bilgisini localStorage'dan sil
        // dispatch(setCurrentUser(null)); // Redux store'dan kullanıcıyı null yap
        history.push("/login"); // Login sayfasına yönlendir
      } else {
        history.goBack(); // Geri dön
      }
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  // Logout işlemini otomatik olarak başlat
  React.useEffect(
    () => {
      handleLogout();
    },
    // eslint-disable-next-line
    []
  );

  return null; // Boş bir sayfa, hiçbir şey render etmiyoruz
};

export default LogoutPage;
