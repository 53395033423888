import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "components/loading/Loading";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import Servis from "../../services/servis";
import NumberFormat from "react-number-format";

import DetayHarita from "./Container/DetayHarita";
import YatirimResimleri from "./Container/YatirimResimleri";
import { ilceler } from "../../config";

const YatirimDetay = () => {
  const { id } = useParams();
  const [yatirim, setYatirim] = useState({});
  const [loading, setLoading] = useState(true);
  const { currentUser } = useSelector((state) => state.auth);

  const yillar = [
    { value: "2018", label: "2018" },
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
  ];

  const [seciliYil, setSeciliYil] = useState();
  const durumlar = [
    { value: "İhale Aşamasında", label: "İhale Aşamasında" },
    { value: "Yatırım Tamamlandı", label: "Yatırım Tamamlandı" },
    { value: "Yapım Devam Ediyor", label: "Yapım Devam Ediyor" },
    { value: "İptal Edildi", label: "İptal Edildi" },
  ];
  const [seciliDurum, setSeciliDurum] = useState();
  const history = useHistory();
  const validationSchema = Yup.object({
    yatirimadi: Yup.string().required("Yatırım Adı giriniz"),
    koy: Yup.string().required("Köy veya Mahalle giriniz"),
    yatirimaciklama: Yup.string().required("Yatırım Açıklaması giriniz"),
    yukleyen: Yup.string().required("Yükleyen giriniz"),
  });

  const formik = useFormik({
    initialValues: {
      yil: "",
      yatirimadi: "",
      yatirimtutari: "",
      ilce: "",
      koy: "",
      yatirimaciklama: "",
      durumu: "",
      yukleyen: "",
      islemyapantel: "",
      lat: 0,
      lng: 0,
      aciklama: "",
      sorun: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const degerler = {
        id: id,
        yatirimyili: seciliYil.value,
        yatirimadi: values.yatirimadi,
        yatirimtutari: values.yatirimtutari,
        ilce: seciliIlce.value,
        koy: values.koy,
        yatirimaciklama: values.yatirimaciklama,
        durumu: seciliDurum.value,
        kurumu: currentUser.kurumadi,
        kurumid: currentUser.kurumid,
        lat: values.lat,
        lng: values.lng,
        yukleyen: values.yukleyen,
        islemyapantel: values.islemyapantel,
        sorun: values.sorun,
      };
      try {
        const resp = await Servis.postYap(
          "yatirimlar/yatirimguncelle",
          degerler
        );
        if (resp.data.status) {
          toast("Yatırım başarıyla güncellendi", {
            className: "success",
          });
          history.push("/yatirimlar/yatirimlistesi");
        }
      } catch (error) {
        toast("Yatırım güncellenirken bir hata oluştu", {
          className: "danger",
        });
      }
    },
  });

  const [seciliIlce, setSeciliIlce] = useState();

  useEffect(
    () => {
      const yatirimDetayGetir = async () => {
        setLoading(true);
        const resp = await Servis.getYap("yatirimlar/yatirimdetay/" + id);
        if (resp.status) {
          setYatirim(resp.data);
          setSeciliYil(
            yillar.find((x) => x.value === resp.data.yatirimyili.toString())
          );
          setSeciliDurum(durumlar.find((x) => x.value === resp.data.durumu));
          setSeciliIlce(ilceler.find((x) => x.value === resp.data.ilce));
          formik.setValues({
            ...formik.values,
            yatirimadi: resp.data.yatirimadi,
            yatirimtutari: resp.data.yatirimtutari,
            koy: resp.data.koy,
            yatirimaciklama: resp.data.yatirimaciklama,
            sorun: resp.data.sorun,
            lat: resp.data.lat,
            lng: resp.data.lng,
            yukleyen: currentUser.adsoyad,
            islemyapantel: "0" + currentUser.telno,
          });
          setLoading(false);
        }
      };
      yatirimDetayGetir();
    },
    // eslint-disable-next-line
    [id]
  );

  const yilOnChange = (e) => {
    setSeciliYil(e);
    formik.setFieldValue("yil", e.value);
  };

  const durumOnChange = (e) => {
    setSeciliDurum(e);
    formik.setFieldValue("durumu", e.value);
  };

  const handleMarkerPositionChange = (position) => {
    formik.setFieldValue("lat", position.lat);
    formik.setFieldValue("lng", position.lng);
  };

  const yatirimSil = async () => {
    Swal.fire({
      title: "Emin misiniz?",
      text: "Yatırım Silmek İstediğinizden Emin Misiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Evet, Sil!",
      cancelButtonText: "İptal",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "T.C. Kimlik Numaranızı Giriniz",
          text: "Silindiğine dair bilgiler kaydedilecektir",
          input: "text",
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonText: "Sil",
          showLoaderOnConfirm: true,
          cancelButtonText: "İptal",
          preConfirm: async (tcno) => {
            if (tcno === "") {
              Swal.showValidationMessage("T.C. Kimlik Numarası Boş Olamaz");
            } else if (tcno !== currentUser.tcno) {
              Swal.showValidationMessage("T.C. Kimlik Numarası Hatalı");
            } else {
              try {
                await Servis.postYap("yatirimlar/yatirimsil", {
                  id: id,
                  tcno: tcno,
                  adsoyad: currentUser.adsoyad,
                });

                history.push("/yatirimlar/yatirimlistesi");
              } catch (error) {
                Swal.showValidationMessage(`
                Hata Oluştu: ${error}
              `);
              }
            }
          },
          allowOutsideClick: () => !Swal.isLoading(),
        }).then((result) => {
          if (result.isConfirmed) {
            toast("Yatırım başarıyla silindi", {
              className: "success",
            });
          }
        });
      }
    });
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Form onSubmit={formik.handleSubmit}>
            <Card>
              <Card.Body>
                <Row>
                  <Col md={10} className="mb-4">
                    <h3>{yatirim.yatirimadi}</h3>
                  </Col>
                  <Col md={2} className="mb-4">
                    <div className="d-flex justify-content-end">
                      <Button variant="danger" onClick={yatirimSil}>
                        Yatırımı Sil
                      </Button>
                    </div>
                  </Col>
                </Row>
                <hr className="mt-0 mb-4" />

                <Row>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>Yatırım Yılı</Form.Label>
                      <Select
                        options={yillar}
                        value={seciliYil}
                        onChange={yilOnChange}
                        classNamePrefix="react-select"
                        placeholder="Lütfen Seçiniz"
                      />
                      {formik.errors.yil && formik.touched.yil && (
                        <Form.Text className="text-danger">
                          {formik.errors.yil}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>Yatırım Adı</Form.Label>
                      <Form.Control
                        type="text"
                        name="yatirimadi"
                        value={formik.values.yatirimadi}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.errors.yatirimadi && formik.touched.yatirimadi
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.yatirimadi}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>Yatırım Tutarı</Form.Label>
                      <NumberFormat
                        className="form-control"
                        thousandSeparator="."
                        decimalSeparator=","
                        thousandsGroupStyle="thousand"
                        prefix="₺"
                        name="yatirimtutari"
                        value={formik.values.yatirimtutari}
                        onValueChange={(values) => {
                          const { value } = values;
                          formik.setFieldValue("yatirimtutari", value);
                        }}
                      />
                      {formik.errors.yatirimtutari &&
                        formik.touched.yatirimtutari && (
                          <Form.Text className="text-danger">
                            {formik.errors.yatirimtutari}
                          </Form.Text>
                        )}
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>Proje Durumu</Form.Label>
                      <Select
                        options={durumlar}
                        value={seciliDurum}
                        onChange={durumOnChange}
                        classNamePrefix="react-select"
                        placeholder="Lütfen Seçiniz"
                      />
                      {formik.errors.durumu && formik.touched.durumu && (
                        <Form.Text className="text-danger">
                          {formik.errors.durumu}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>İlçe</Form.Label>
                      <Select
                        options={ilceler}
                        value={seciliIlce}
                        onChange={(e) => {
                          setSeciliIlce(e);
                          formik.setFieldValue("ilce", e.value);
                        }}
                        classNamePrefix="react-select"
                        placeholder="Lütfen Seçiniz"
                      />
                      {formik.errors.ilce && formik.touched.ilce && (
                        <Form.Text className="text-danger">
                          {formik.errors.ilce}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>Köy veya Mahalle</Form.Label>
                      <Form.Control
                        type="text"
                        name="koy"
                        value={formik.values.koy}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.koy && formik.touched.koy}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.koy}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>Açıklama</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={8}
                        name="yatirimaciklama"
                        value={formik.values.yatirimaciklama}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.errors.yatirimaciklama &&
                          formik.touched.yatirimaciklama
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.yatirimaciklama}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>Sorun ve Çözüm</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={8}
                        name="sorun"
                        value={formik.values.sorun}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.sorun && formik.touched.sorun}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.sorun}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <DetayHarita
              lat={formik.values.lat}
              lng={formik.values.lng}
              onMarkerPositionChange={handleMarkerPositionChange}
            />
            <YatirimResimleri resimler={yatirim.resimler} yatirimId={id} />
            <Card className="mt-4">
              <Card.Body>
                <h2>Yükleyen Bilgileri</h2>
                <hr className="mt-1 mb-4" />
                <Row>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>Yükleyen</Form.Label>
                      <Form.Control
                        type="text"
                        name="yukleyen"
                        value={formik.values.yukleyen}
                        onChange={formik.handleChange}
                        isInvalid={
                          formik.errors.yukleyen && formik.touched.yukleyen
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.yukleyen}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-4">
                    <Form.Group>
                      <Form.Label>Yükleyen Telefon</Form.Label>
                      <NumberFormat
                        format="#(###) ###-####"
                        mask="_"
                        className="form-control"
                        allowEmptyFormatting
                        name="islemyapantel"
                        value={formik.values.islemyapantel}
                        onValueChange={(values) => {
                          const { value } = values;
                          formik.setFieldValue("islemyapantel", value);
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.islemyapantel}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mb-4">
                    <div className="d-flex justify-content-end">
                      <Button variant="primary" type="submit">
                        Güncelle
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        </>
      )}
    </>
  );
};

export default YatirimDetay;
