import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Form } from "react-bootstrap";
import HtmlHead from "components/html-head/HtmlHead";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2/dist/sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
import Servis from "../../../services/servis";

const KurumVeriBasliklari = ({ kurumid }) => {
  const [veriler, setVeriler] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingId, setEditingId] = useState(null); // State to track which item is being edited
  const [editedBaslik, setEditedBaslik] = useState(""); // State to store edited baslik

  useEffect(() => {
    const veriCek = async () => {
      try {
        const response = await Servis.getYap(
          "veriler/veribaslikgetir/" + kurumid
        );

        setVeriler(response.data);
      } catch (error) {
        console.error("Veri çekme hatası:", error);
      } finally {
        setLoading(false);
      }
    };

    if (kurumid) {
      veriCek();
    }
  }, [kurumid]);

  const sil = (id) => {
    Swal.fire({
      title: "Emin misiniz?",
      text: "Bu işlem geri alınamaz! Verileriniz de tamamen silinecektir.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Evet, sil!",
      cancelButtonText: "Vazgeç",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await Servis.postYap("veriler/veribasliksil", {
            id,
          });
          toast("Veri başlığı silindi", {
            className: "success",
          });
          setVeriler((prevState) =>
            prevState.filter((veri) => veri._id !== id)
          );
        } catch (error) {
          toast("Veri başlığı silinirken bir hata oluştu", {
            className: "danger",
          });
          console.log("Veri silme hatası:", error);
        }
      }
    });
  };

  const handleEdit = (id, baslik) => {
    setEditingId(id);
    setEditedBaslik(baslik);
  };

  const handleUpdate = async (id) => {
    try {
      await Servis.postYap("veriler/veribaslikguncelle", {
        id,
        veriadi: editedBaslik,
      });
      // Yeniden veri çekme ve verileri güncelleme
      const response = await Servis.getYap(
        "veriler/veribaslikgetir/" + kurumid
      );
      setVeriler(response.data);
      setEditingId(null);
    } catch (error) {
      toast("Veri başlığı güncellenirken bir hata oluştu", {
        className: "danger",
      });
      console.log("Veri güncelleme hatası:", error);
    }
  };

  return (
    <>
      <HtmlHead title="Veri Başlık Ekle" description="" />
      <Row className="g-2 mb-5">
        <Col sm="12" xl="12" className="mb-5">
          <Card className="mb-5">
            <Card.Body>
              <h2>Kurumunuza Ait Veriler</h2>
              <hr />
              {loading ? (
                <p>Yükleniyor...</p>
              ) : (
                <Row>
                  <Col md="12" className="mb-3">
                    <Table
                      responsive
                      className="table table-striped table-hover"
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Veri Başlığı</th>
                          <th>Kurum Adı</th>
                          <th className="text-center">İşlemler</th>
                        </tr>
                      </thead>
                      <tbody>
                        {veriler.map((veri, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {editingId === veri._id ? (
                                <Form.Control
                                  type="text"
                                  size="sm"
                                  value={editedBaslik}
                                  onChange={(e) =>
                                    setEditedBaslik(e.target.value)
                                  }
                                />
                              ) : (
                                veri.veriadi
                              )}
                            </td>
                            <td>{veri.kurumadi}</td>
                            <td className="text-center">
                              {editingId === veri._id ? (
                                <>
                                  <Button
                                    variant="success"
                                    size="sm"
                                    onClick={() => handleUpdate(veri._id)}
                                  >
                                    Güncelle
                                  </Button>{" "}
                                  <Button
                                    variant="secondary"
                                    size="sm"
                                    onClick={() => setEditingId(null)}
                                  >
                                    İptal
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    variant="info"
                                    size="sm"
                                    onClick={() =>
                                      handleEdit(veri._id, veri.veriadi)
                                    }
                                  >
                                    Düzenle
                                  </Button>{" "}
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => sil(veri._id)}
                                  >
                                    Sil
                                  </Button>
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default KurumVeriBasliklari;
