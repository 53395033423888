import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_USER, IS_DEMO } from "config.js";

const initialState = {
  isLogin: false,
  currentUser: IS_DEMO ? DEFAULT_USER : {},
};

export const setCurrentUser = (user) => {
  return {
    type: "auth/setCurrentUser",
    payload: user,
  };
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
      state.isLogin = true;
    },
  },
});

const authReducer = authSlice.reducer;

export default authReducer;
