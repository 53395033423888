import React from "react";
import { Card, Row, Col } from "react-bootstrap";

const YatirimAyrinti = ({ yatirim }) => {
  const paraFormat = (para) => {
    return new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
    })
      .format(para)
      .replace("₺", "");
  };

  return (
    <Card>
      <Card.Body>
        <hr className="mt-0 mb-4" />
        <Row>
          <Col md={6}>
            <h5>
              <b>Toplam Yatırım Sayısı : {yatirim.length}</b>
            </h5>
          </Col>
          <Col md={6}>
            <h5>
              <b className="text-danger">
                Toplam Yatırım Tutarı :{" "}
                {paraFormat(
                  yatirim.reduce((acc, item) => acc + item.yatirimtutari, 0)
                )}
              </b>
            </h5>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default YatirimAyrinti;
