import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "components/loading/Loading";
import "react-toastify/dist/ReactToastify.css";
import HtmlHead from "components/html-head/HtmlHead";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Servis from "services/servis";
import { tarihFormat } from "config";

const KurumFaaliyetDetay = () => {
  const { id } = useParams();
  const [gelenFaalieyet, setGelenFaalieyet] = useState({});
  const [loading, setLoading] = useState(true);
  const [editorState, setEditorState] = useState("");
  const [egitimEditorState, setegitimEditorState] = useState("");
  const [saglikEditorState, setsaglikEditorState] = useState("");
  const [tarimEditorState, settarimEditorState] = useState("");
  const [sosyalYardimEditorState, setsosyalYardimEditorState] = useState("");
  const [kirsalAltYapiEditorState, setkirsalAltYapiEditorState] = useState("");
  const [digerKamuHizmetleriEditorState, setdigerKamuHizmetleriEditorState] =
    useState("");
  const [projeFaaliyetlerEditorState, setprojeFaaliyetlerEditorState] =
    useState("");
  const editorRef = useRef(null); // Ref for the editor

  useEffect(() => {
    // Editor yüksekliğini güncelle
    if (editorRef.current) {
      const editorHeight = editorRef.current.getEditor().root.scrollHeight;
      editorRef.current.getEditor().root.style.height = `${editorHeight}px`;
    }
  }, [editorState]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Servis.getYap("ilcerapor/ilcerapordetay/" + id);
        setLoading(false);
        setGelenFaalieyet(response.data);
        setEditorState(response.data.asayis);
        setegitimEditorState(response.data.egitim);
        setsaglikEditorState(response.data.saglik);
        settarimEditorState(response.data.tarim);
        setsosyalYardimEditorState(response.data.sosyalyardim);
        setkirsalAltYapiEditorState(response.data.altyapi);
        setdigerKamuHizmetleriEditorState(response.data.diger);
        setprojeFaaliyetlerEditorState(response.data.projeler);
      } catch (error) {
        toast("Faaliyet getirme hatası: " + error, { className: "error" });
      }
    };

    fetchData();
  }, [id]);

  return (
    <>
      {loading && <Loading />}
      <HtmlHead title="Kurum Faaliyet Detay" />
      <section className="scroll-section" id="scrollbar">
        <Card>
          <Card.Body>
            <h4>
              <b>
                {gelenFaalieyet.kurumu} {gelenFaalieyet.yil} {gelenFaalieyet.ay}{" "}
                {gelenFaalieyet.donem}
              </b>
            </h4>
            <hr className="mb-5" />

            <Row>
              <Col md={12} className="mb-3">
                <Form.Group className="mb-3">
                  <h5 className="mt-3 mb-3">
                    <b>Asayiş ve Güvenlik</b>
                  </h5>
                  <hr className="mb-3" />
                  <ReactQuill
                    theme="snow"
                    value={editorState}
                    onChange={setEditorState}
                    ref={editorRef}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group className="mb-3">
                  <h5 className="mt-3 mb-3">
                    <b>Eğitim Öğretim</b>
                  </h5>
                  <hr className="mb-3" />
                  <ReactQuill
                    theme="snow"
                    value={egitimEditorState}
                    onChange={setegitimEditorState}
                    ref={editorRef}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group className="mb-3">
                  <h5 className="mt-3 mb-3">
                    <b>Sağlık</b>
                  </h5>
                  <hr className="mb-3" />
                  <ReactQuill
                    theme="snow"
                    value={saglikEditorState}
                    onChange={setsaglikEditorState}
                    ref={editorRef}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group className="mb-3">
                  <h5 className="mt-3 mb-3">
                    <b>Tarım</b>
                  </h5>
                  <hr className="mb-3" />
                  <ReactQuill
                    theme="snow"
                    value={tarimEditorState}
                    onChange={settarimEditorState}
                    ref={editorRef}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group className="mb-3">
                  <h5 className="mt-3 mb-3">
                    <b>Sosyal Yardım</b>
                  </h5>
                  <hr className="mb-3" />
                  <ReactQuill
                    theme="snow"
                    value={sosyalYardimEditorState}
                    onChange={setsosyalYardimEditorState}
                    ref={editorRef}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group className="mb-3">
                  <h5 className="mt-3 mb-3">
                    <b>Kırsal Altyapı</b>
                  </h5>
                  <hr className="mb-3" />
                  <ReactQuill
                    theme="snow"
                    value={kirsalAltYapiEditorState}
                    onChange={setkirsalAltYapiEditorState}
                    ref={editorRef}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group className="mb-3">
                  <h5 className="mt-3 mb-3">
                    <b>Diğer Kamu Hizmetleri</b>
                  </h5>
                  <hr className="mb-3" />
                  <ReactQuill
                    theme="snow"
                    value={digerKamuHizmetleriEditorState}
                    onChange={setdigerKamuHizmetleriEditorState}
                    ref={editorRef}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group className="mb-3">
                  <h5 className="mt-3 mb-3">
                    <b>Proje Faaliyetler</b>
                  </h5>
                  <hr className="mb-3" />
                  <ReactQuill
                    theme="snow"
                    value={projeFaaliyetlerEditorState}
                    onChange={setprojeFaaliyetlerEditorState}
                    ref={editorRef}
                    readOnly={true}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Yükleyen</b>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={gelenFaalieyet.yukleyen}
                    readOnly
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Yükleme Tarihi</b>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={tarihFormat(
                      new Date(gelenFaalieyet.yuklemetarihi).toLocaleDateString(
                        "tr-TR"
                      )
                    )}
                    readOnly
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default KurumFaaliyetDetay;
