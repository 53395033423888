import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "components/loading/Loading";
import "react-toastify/dist/ReactToastify.css";
import HtmlHead from "components/html-head/HtmlHead";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Servis from "services/servis";

const KurumFaaliyetDetay = () => {
  const { id } = useParams();
  const [gelenFaalieyet, setGelenFaalieyet] = useState({});
  const [loading, setLoading] = useState(true);
  const [editorState, setEditorState] = useState("");
  const [projelerEditorState, setProjelerEditorState] = useState("");
  const [digerEditorState, setDigerEditorState] = useState("");
  const editorRef = useRef(null); // Ref for the editor

  useEffect(() => {
    // Editor yüksekliğini güncelle
    if (editorRef.current) {
      const editorHeight = editorRef.current.getEditor().root.scrollHeight;
      editorRef.current.getEditor().root.style.height = `${editorHeight}px`;
    }
  }, [editorState]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Servis.getYap(
          "kurumraporlar/kurumfaaliyetdetay/" + id
        );
        setLoading(false);
        setEditorState(response.data.kurumfaaliyet);
        setProjelerEditorState(response.data.projeler);
        setDigerEditorState(response.data.diger);
        setGelenFaalieyet(response.data);
      } catch (error) {
        toast("Faaliyet getirme hatası: " + error, { className: "error" });
      }
    };

    fetchData();
  }, [id]);

  return (
    <>
      {loading && <Loading />}
      <HtmlHead title="Kurum Faaliyet Detay" />
      <section className="scroll-section" id="scrollbar">
        <Card>
          <Card.Body>
            <h4>
              <b>
                {gelenFaalieyet.kurumu} {gelenFaalieyet.yil} {gelenFaalieyet.ay}{" "}
                {gelenFaalieyet.donem}
              </b>
            </h4>
            <hr className="mb-5" />

            <Row>
              <Col md={12} className="mb-3">
                <Form.Group className="mb-3">
                  <h5 className="mt-3 mb-3">
                    <b>Kurum Faaliyet</b>
                  </h5>
                  <hr className="mb-3" />
                  <ReactQuill
                    theme="snow"
                    value={editorState}
                    readOnly={true}
                    ref={editorRef}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group className="mb-3">
                  <h5 className="mt-3 mb-3">
                    <b>Projeler</b>
                  </h5>
                  <hr className="mb-3" />
                  <ReactQuill
                    theme="snow"
                    value={projelerEditorState}
                    readOnly={true}
                    ref={editorRef}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group className="mb-3">
                  <h5 className="mt-3 mb-3">
                    <b>Diğer</b>
                  </h5>
                  <hr className="mb-3" />
                  <ReactQuill
                    theme="snow"
                    value={digerEditorState}
                    onChange={setDigerEditorState}
                    ref={editorRef}
                    className="sh-70"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </section>
    </>
  );
};

export default KurumFaaliyetDetay;
