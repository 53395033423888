import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import Servis from "../../../services/servis";

const VeriDuzenleModal = ({ show, handleClose, veri, yil, baslikId, handleUpdate }) => {
  const [editedBaslik, setEditedBaslik] = useState("");
  const [aylarData, setAylarData] = useState([]);

  useEffect(() => {
    if (veri) {
      setEditedBaslik(veri.baslik);
      const tempAylar = ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"].map((ay) => {
        const ayVerisi = veri.aylar.find((a) => a.ay === ay);
        return {
          id: ayVerisi ? ayVerisi.id : null, // veriAylar tablosundan gelen ID
          ay,
          veri: ayVerisi ? ayVerisi.veri : "",
        };
      });
      setAylarData(tempAylar);
    }
  }, [veri]);

  const handleAylarChange = (e, ay) => {
    setAylarData((prevAylarData) => prevAylarData.map((item) => (item.ay === ay ? { ...item, veri: e.target.value } : item)));
  };

  // Veri güncelleme işlevi
  const handleUpdateClick = async () => {
    const aylar = aylarData.map((item) => ({
      id: item.id,
      ay: item.ay,
      veri: item.veri,
    }));
    const sonuc = await Servis.postYap("veri/veriguncelle", {
      yil,
      baslikId,
      aylar,
    });
    if (sonuc.status) {
      // Sonucun başarılı olup olmadığını kontrol edin
      handleUpdate();
      handleClose();
    } else {
      // Hata durumunda kullanıcıya bilgi verilebilir
      console.error("Veri güncelleme hatası:", sonuc.message);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          {yil} Yılı {editedBaslik} {baslikId} Verileri
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {aylarData.map((item) => (
            <Form.Group as={Row} key={item.ay} className="mb-3">
              <Form.Label column sm={2}>
                {item.ay}
              </Form.Label>
              <Col sm={10}>
                <Form.Control size="sm" type="text" value={item.veri} onChange={(e) => handleAylarChange(e, item.ay)} />
              </Col>
            </Form.Group>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Kapat
        </Button>
        <Button variant="outline-primary" onClick={handleUpdateClick}>
          Kaydet
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VeriDuzenleModal;
