import axios from "axios";
import { API_LINK } from "config";

class ServisService {
  fileUpload(formData) {
    return axios.post(`${API_LINK}api/yatirimlar/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": localStorage.getItem("token"),
      },
    });
  }

  resimYukleYeni(resimler) {
    const formData = new FormData();
    resimler.forEach((resim) => {
      formData.append("resim", resim);
    });
    return axios.post(`${API_LINK}api/yatirimlar/resimyukleyeni`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-access-token": localStorage.getItem("token"),
      },
    });
  }

  login(data) {
    return axios.post(`${API_LINK}login`, data);
  }

  logout(data) {
    return axios.post(`${API_LINK}logout`, data);
  }
  sifregonder(data) {
    return axios.post(`${API_LINK}sifregonder`, data);
  }

  verifyToken() {
    return axios.get(`${API_LINK}tokendogrula`, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
  }

  postYap(endpoint, data) {
    return axios.post(`${API_LINK}api/${endpoint}`, data, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
  }

  getYap(endpoint) {
    return axios.get(`${API_LINK}api/${endpoint}`, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
  }
  deleteYap(endpoint) {
    return axios.delete(`${API_LINK}api/${endpoint}`, {
      headers: { "x-access-token": localStorage.getItem("token") },
    });
  }

  async postEditor(endpoint, data) {
    const resp = await axios.post(`${API_LINK}api/${endpoint}`, data, {
      headers: {
        "x-access-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
    });
    return resp;
  }
}

// Export the instance of the service
// eslint-disable-next-line import/no-anonymous-default-export
export default new ServisService();
