import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import HtmlHead from "components/html-head/HtmlHead";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useFormik } from "formik";

import KurumVeriBasliklari from "./Container/KurumVeriBasliklari";
import Servis from "../../services/servis";

const VeriBaslikEkle = () => {
  const title = "Veri Başlık Ekle";
  const description = "";

  const { currentUser } = useSelector((state) => state.auth);

  const [veriEklendi, setVeriEklendi] = useState(false); // Yeni state ekledik

  const validationSchema = Yup.object().shape({
    baslik: Yup.string()
      .required("Bu alan zorunludur")
      .min(6, "Başlık en az 6 karakter olmalıdır"),
  });

  const formik = useFormik({
    initialValues: {
      baslik: "",
    },
    validationSchema,
    onSubmit: (values) => {
      try {
        Servis.postYap("veriler/baslikeklekurum", {
          baslik: values.baslik,
          kurumid: currentUser.kurumid,
          kurumadi: currentUser.kurumadi,
        }).then((response) => {
          if (response.data.status === true) {
            toast("Veri başlığı başarıyla eklendi", {
              className: "success",
            });
            setVeriEklendi(!veriEklendi);
            formik.resetForm();
          } else {
            toast(response.data.message, {
              className: "danger",
            });
          }
        });
      } catch (error) {
        toast("Veri başlığı eklenirken bir hata oluştu", {
          className: "danger",
        });
      }
    },
  });

  const { handleChange, handleSubmit, values, touched, errors } = formik;

  return (
    <>
      <HtmlHead title={title} description={description} />

      <Row className="g-2">
        <Col sm="12" xl="12" className="mb-5">
          <Card className="mb-5">
            <Card.Body>
              <h2>Yeni Başlık Ekle</h2>
              <hr />

              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md="11" className="mb-3">
                    <Form.Group id="baslik">
                      <Form.Label>Veri Başlığınız</Form.Label>
                      <Form.Control
                        type="text"
                        name="baslik"
                        value={values.baslik}
                        onChange={handleChange}
                        placeholder="Başlık Giriniz"
                      />
                      {touched.baslik && errors.baslik && (
                        <div className="d-block invalid-tooltip">
                          {errors.baslik}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md="1" className="mb-3 mt-5">
                    <Button variant="primary" type="submit">
                      Kaydet
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {currentUser && (
        <KurumVeriBasliklari key={veriEklendi} kurumid={currentUser.kurumid} />
      )}
      {/* Burada key prop ile KurumVeriBasliklari bileşenini güncelleyeceğiz */}
      {/* {gelenKullanici && (
        <KurumVeriBasliklari
          key={veriEklendi}
          kurumid={gelenKullanici.kurumid}
        />
      )} */}
    </>
  );
};

export default VeriBaslikEkle;
