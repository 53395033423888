import React, { useEffect, useState } from "react";
import { Row, Col, Card, Table, Button, Form, Alert } from "react-bootstrap";
import { useSelector } from "react-redux";
import HtmlHead from "components/html-head/HtmlHead";
import Servis from "../../services/servis";

const KurumSabitiVeriler = () => {
  const [veriler, setVeriler] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useSelector((state) => state.auth);
  const [editingId, setEditingId] = useState(null);
  const [editedVeri, setEditedVeri] = useState("");

  const title = "Kurum Sabit Veriler";
  const description = "";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Servis.postYap("sabitveriler/kurumsabitveriler", {
          kurumid: currentUser.kurumid,
        });
        setVeriler(response.data);
      } catch (error) {
        console.error("Veri çekme hatası:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser.kurumid]);

  const handleDuzenleClick = (veri) => {
    setEditingId(veri._id);
    setEditedVeri(veri.veri);
  };

  const handleUpdateClick = async (id) => {
    try {
      await Servis.postYap("sabitveriler/veriguncelle", {
        id,
        veri: editedVeri,
      });
      const updatedVeriler = veriler.map((veri) => (veri._id === id ? { ...veri, veri: editedVeri } : veri));
      setVeriler(updatedVeriler);
      setEditingId(null);
    } catch (error) {
      console.error("Veri güncelleme hatası:", error);
    }
  };

  const handleCancelClick = () => {
    setEditingId(null);
    setEditedVeri("");
  };

  return (
    <>
      <HtmlHead title={title} description={description} />
      <Row className="g-2">
        <Col sm="12" xl="12" className="mb-5">
          <Card className="mb-5">
            <Card.Body>
              <h2>Kurum Sabit Veriler</h2>
              <hr />
              <Alert variant="info">
                <p>
                  <strong>Sayın Kurum Kullanıcısı:</strong> Bu alana aylık değişmeyen verilerinizi ekleyebilir ve güncelleyebilirsiniz. Aylık değişmeyen veriler 6 ay veya daha fazla süreyle değişmeyen
                  verilerdir. Bu verileri eklerken ve güncellerken dikkatli olunuz.
                </p>
                <p>
                  <strong>Not:</strong> Verilerinizi eklemek için öncelikle veri başlığı eklemeniz gerekmektedir. Veri başlığı eklemek için <a href="/sabitveriler/sabitveribaslik">tıklayınız</a>.
                </p>
              </Alert>
              <hr className="mb-4" />
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              {loading ? (
                <div className="text-center">Veriler yükleniyor...</div>
              ) : (
                <Table responsive hover size="lg">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Veri Adı</th>
                      <th>Veri</th>
                      <th className="text-center">İşlemler</th>
                    </tr>
                  </thead>
                  <tbody>
                    {veriler.map((veri, index) => (
                      <tr key={veri._id}>
                        <td>{index + 1}</td>
                        <td>{veri.veriadi}</td>
                        <td>{editingId === veri._id ? <Form.Control type="text" size="sm" value={editedVeri} onChange={(e) => setEditedVeri(e.target.value)} /> : veri.veri}</td>
                        <td className="text-center">
                          {editingId === veri._id ? (
                            <>
                              <Button variant="outline-success" size="sm" onClick={() => handleUpdateClick(veri._id)}>
                                Kaydet
                              </Button>{" "}
                              <Button variant="outline-secondary" size="sm" onClick={handleCancelClick}>
                                İptal
                              </Button>
                            </>
                          ) : (
                            <Button variant="outline-primary" size="sm" onClick={() => handleDuzenleClick(veri)}>
                              Düzenle
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default KurumSabitiVeriler;
